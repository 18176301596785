import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import { isEmpty } from 'lodash';
import withLoader from '../../helpers/withloader';
import { AutoCompleteCompleteMethodParams, ITrekLookupModel, IUserLookupModel } from '../../models/index';
import TrekService from '../../api/TrekService';
import LookupService from '../../api/LookupService';
import { ITrekSeasons } from '../../models/Trek/TrekSeasons';
import BatchService from '../../api/BatchService';
import { IBatchModel } from '../../models/Batch/BatchFormModel';
import { IBatchApiModel } from '../../models/Batch/BatchApiModel';
import { BatchPromoter } from '../../models/Batch/BatchPromoterLookupModel';
import { ISpecialTrekTypeModel } from '../../models/Trek/SpecialTrekModel';
import { compareDates } from '../../helpers/utils';
import Overlay from '../common/Overlay';

interface MatchParams {
  id: string;
}

const BatchDetail: React.FC<RouteComponentProps<MatchParams>> = (props) => {
  const { params } = props.match;
  const id: number = parseInt(params.id, 10);
  const isAddMode = !id;
  const toast = useRef(null);

  const [autoTrekValueData, setAutoTrekValueData] = useState<ITrekLookupModel[]>([]);
  const [selectedTrekValue, setSelectedTrekValue] = useState<ITrekLookupModel | null>(null);
  const [selectedSpecialTrekValue, setSelectedSpecialTrekValue] = useState<ISpecialTrekTypeModel | null>(null);
  const [autoFilteredValue, setAutoFilteredValue] = useState<ITrekLookupModel[]>([]);

  const [autoSeasonsValueData, setAutoSeasonsValueData] = useState<ITrekSeasons[]>([]);
  const [autoSeasonsFilteredValue, setSeasonsFilteredValue] = useState<ITrekSeasons[]>([]);

  const [autoLeaderUserData, setAutoLeaderUserData] = useState<IUserLookupModel[]>([]);
  const [autoFilteredLeaderUserValue, setAutoLeaderTransportUserValue] = useState<IUserLookupModel[]>([]);

  const [autoPromoterUserData, setAutoPromoterUserData] = useState<BatchPromoter[]>([]);
  const [autoFilteredPromoterUserValue, setAutoFilteredPromoterUserValue] = useState<BatchPromoter[]>([]);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [isSpecialBatch, setIsSpecialBatch] = useState<boolean>(false);
  const [specialTrekTypes, setSpecialTrekTypes] = useState<ISpecialTrekTypeModel[]>([]);
  const [specialTrekFilteredValues, setSpecialTrekFilteredValues] = useState<ISpecialTrekTypeModel[]>([]);
  const [selectedSeasonData, setSelectedSeasonData] = useState<ITrekSeasons>();
  const [isSeasonsDataLoaded, setIsSeasonsDataLoaded] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const defaultValues = {
    trekId: 0,
    seasonId: null,
    startDate: new Date(),
    endDate: new Date(),
    trekLeaders: [],
    numberOfActiveBooking: 0,
    numberOfConfirmedBooking: 0,
    numberOfUnConfirmedBooking: 0,
    numberOfWaitingListBooking: 0,
  };

  const validationSchema: any = Yup.object({
    trekId: Yup.number().required('Trek is required'),
    seasonId: Yup.object().required('Season is required'),
    waitCapacity: Yup.number()
      .required('WaitCapacity is required')
      .test(
        'validate-wait-capacity',
        "Wait Capacity can't be less than the number of Active Waiting list bookings",
        (capacity?: number) => {
          const numberOfWaitingListBooking: number = getValues('numberOfWaitingListBooking') || 0;
          if (capacity === undefined || capacity < numberOfWaitingListBooking) {
            return false;
          }
          return true;
        },
      ),
    capacity: Yup.number()
      .required('Capacity is required')
      .moreThan(0)
      .test(
        'validate-capacity',
        "Capacity can't be less than the sum total of number of Active bookings and number of Confirmed bookings",
        (capacity?: number) => {
          const numberOfActiveBooking: number = getValues('numberOfActiveBooking') || 0;
          const numberOfConfirmedBooking: number = getValues('numberOfConfirmedBooking') || 0;
          if (capacity === undefined || capacity < numberOfActiveBooking + numberOfConfirmedBooking) {
            return false;
          }
          return true;
        },
      ),
    startDate: Yup.date()
      .required('Start Date is required')
      .test('compare-start-dates', "*Batch's Start Date* should be AFTER *Season's Start Date*", (value?: Date) => {
        if (selectedSeasonData?.startDate && value) return compareDates(value, selectedSeasonData.startDate);
        return false;
      }),
    endDate: Yup.date()
      .required('End Date is required')
      .test('enddate-comparer', 'End date should be greater than Start date', (value?: Date) => {
        const startDate: Date | undefined = getValues('startDate');
        if (value && startDate) return compareDates(value, startDate, true);
        return false;
      })
      .test('compare-end-dates', "*Batch's End Date* should be BEFORE *Season's End Date*", (value?: Date) => {
        if (selectedSeasonData?.endDate && value) return compareDates(selectedSeasonData.endDate, value);
        return false;
      }),
  });

  const { handleSubmit, reset, setValue, control, errors, getValues } = useForm<IBatchModel>({
    resolver: yupResolver(validationSchema),
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues,
  });

  const filteredUsers = (userData: IUserLookupModel[], filterIds: number[]) => {
    const items: IUserLookupModel[] = [];
    filterIds?.forEach((filterId) => {
      const val = userData?.find((y) => y.id === filterId);
      if (val !== undefined) items.push(val);
    });
    return items;
  };

  const bindSeasons = useCallback(
    (trekId: number, flag = 1, seasonId?: number) => {
      const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      const formatYmd = (dt: Date) => `${dt.getDate()}-${months[dt.getMonth()]}-${dt.getFullYear()}`;

      const formattedDateText = (startDate: Date, endDate: Date) => {
        return `${formatYmd(new Date(startDate))} - ${formatYmd(new Date(endDate))}`;
      };
      setValue('seasonId', null);
      setIsSeasonsDataLoaded(false);
      setSeasonsFilteredValue([]);
      setAutoSeasonsValueData([]);
      if (trekId > 0) {
        const lookupService = LookupService;
        lookupService.getTrekSeasons(trekId).then((seasonsData) => {
          const dt: ITrekSeasons[] = [];
          seasonsData.forEach((season) => {
            const trekName = season.specialTrekType
              ? specialTrekTypes.find((st) => st.id === season.specialTrekType)?.name
              : 'REGULAR';
            const trekFees = season.seasonFees?.filter((fee) => fee.feeType === 'TREK')[0].amount || 0;
            const formattedText = `${formattedDateText(season.startDate, season.endDate)} (${trekName} - ₹${trekFees})`;
            dt.push({
              id: season.id,
              formattedText,
              trekId: season.trekId,
              startDate: season.startDate,
              endDate: season.endDate,
              trek: season.trek,
            });
          });
          if (!dt.length) {
            const tst: any = toast?.current;
            tst.show({ severity: 'warn', summary: 'No Season found for the Selected Trek', detail: '' });
          }
          setAutoSeasonsValueData(dt);
          if (flag === 2) {
            const selectedSeason = dt.find((x) => x.id === seasonId);
            setSelectedSeasonData(selectedSeason);
            setValue('seasonId', selectedSeason);
          }
          setIsSeasonsDataLoaded(true);
        });
      }
    },
    [setValue, specialTrekTypes],
  );

  const getAndBindData = useCallback(
    (
      trekData: ITrekLookupModel[],
      trekLeaders: IUserLookupModel[],
      transportUsers: BatchPromoter[],
      specialTreks: ISpecialTrekTypeModel[],
    ) => {
      // get user and set form fields
      BatchService.getById(id).then((batchApiData: IBatchApiModel) => {
        const selectedTrekObject = trekData?.find((x) => x.trekId === batchApiData.trekId);
        const selectedSpecialTrekObject = specialTreks.find((x) => x.id === batchApiData.specialTrekType);
        setSelectedTrekValue(selectedTrekObject || null);
        setSelectedSpecialTrekValue(selectedSpecialTrekObject || null);

        const selectedLeaders = filteredUsers(trekLeaders, batchApiData.trekLeaderIds);
        const selectedPromoter = transportUsers.find((x) => x.id === batchApiData.promoterId);
        bindSeasons(batchApiData.trekId, 2, batchApiData.seasonId);
        if (batchApiData.specialTrekType) {
          setIsSpecialBatch(true);
        }

        setValue('trekId', batchApiData.trekId);
        setValue('specialTrek', batchApiData.specialTrekType);
        setValue('promoter', selectedPromoter);
        setValue('trekLeaders', selectedLeaders);
        setValue('startDate', new Date(batchApiData.startDate));
        setValue('endDate', new Date(batchApiData.endDate));
        setValue('capacity', batchApiData.capacity);
        setValue('waitCapacity', batchApiData.waitCapacity);
        setValue('whatsappGroupLink', batchApiData.whatsappGroupLink);
        setValue('preTrekBriefingLink', batchApiData.preTrekBriefingLink);
        setValue('numberOfActiveBooking', batchApiData.numberOfActiveBooking);
        setValue('numberOfConfirmedBooking', batchApiData.numberOfConfirmedBooking);
        setValue('numberOfUnConfirmedBooking', batchApiData.numberOfUnConfirmedBooking);
        setValue('numberOfWaitingListBooking', batchApiData.numberOfWaitingListBooking);

        setDataLoaded(true);
      });
    },
    [bindSeasons, id, setValue],
  );

  useEffect(() => {
    const lookupService = LookupService;
    lookupService.getSpecialTrekTypes().then((specialTreks) => {
      setSpecialTrekTypes(specialTreks);
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(specialTrekTypes)) {
      const trekService = TrekService;
      const lookupService = LookupService;
      trekService.getByLookupFormat({ fetchFamilyNamedTreks: false }).then((trekData) => {
        setAutoTrekValueData(trekData);
        lookupService.getUser('TREK_LEADER').then((trekLeaders) => {
          setAutoLeaderUserData(trekLeaders);
          lookupService.getBatchPromoters().then((transportUsers) => {
            setAutoPromoterUserData(transportUsers);
            if (!isAddMode) getAndBindData(trekData, trekLeaders, transportUsers, specialTrekTypes);
            else setDataLoaded(true);
          });
        });
      });
    }
  }, [getAndBindData, isAddMode, specialTrekTypes]);

  const autoSearchTrek = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setAutoFilteredValue([...autoTrekValueData]);
    } else {
      const avValue: any = autoTrekValueData;
      setAutoFilteredValue(
        avValue.filter((location: any) => {
          return location.name.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchTrekLeaders = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setAutoLeaderTransportUserValue([...autoLeaderUserData]);
    } else {
      setAutoLeaderTransportUserValue(
        autoLeaderUserData.filter((user: any) => {
          return user?.firstName?.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchSeasons = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setSeasonsFilteredValue([...autoSeasonsValueData]);
    } else {
      setSeasonsFilteredValue(
        autoSeasonsValueData.filter((user: any) => {
          return user?.firstName?.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const specialTreksProcessedValues = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setSpecialTrekFilteredValues([...specialTrekTypes]);
    } else {
      setSpecialTrekFilteredValues(
        specialTrekTypes.filter((trekType: ISpecialTrekTypeModel) => {
          return trekType.name.toLocaleLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const autoSearchPromoters = (event: AutoCompleteCompleteMethodParams) => {
    if (!event.query.trim().length) {
      setAutoFilteredPromoterUserValue([...autoPromoterUserData]);
    } else {
      setAutoFilteredPromoterUserValue(
        autoPromoterUserData.filter((user: any) => {
          return user?.firstName?.toLowerCase().startsWith(event.query.toLowerCase());
        }),
      );
    }
  };

  const createBatch = (data: IBatchModel) => {
    return BatchService.create(data)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ' Group Created Successfully', detail: '' });
        window.close();
      })
      .catch((res) => {
        setSaveInProgress(false);
        const tst: any = toast?.current;
        if (res?.response?.data?.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Creation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      });
  };

  const updateBatch = (batchId: number, data: IBatchModel) => {
    return BatchService.update(batchId, data)
      .then(() => {
        const tst: any = toast?.current;
        tst.show({ severity: 'success', summary: ' Group updated Successfully', detail: '' });
        window.close();
      })
      .catch((res) => {
        setSaveInProgress(false);
        const tst: any = toast?.current;
        if (res?.response?.data?.message)
          tst.show({ severity: 'error', summary: `${res.response.data.message}`, detail: '' });
        else
          tst.show({
            severity: 'error',
            summary: 'Updation failed;Re-try in few mins. ...If not succeeded contact support team',
            detail: '',
          });
      });
  };

  const onSubmit = (data: IBatchModel): Promise<void> | null => {
    const tst: any = toast?.current;
    let errorCase = false;
    let errorMsg = '';
    if (isSpecialBatch) {
      if (!data.specialTrek) {
        errorCase = true;
        errorMsg = 'Special Batch is Checked, Need to provide the value for Special Trek Type';
      }
    }
    if (errorCase) {
      tst.show({
        severity: 'error',
        summary: errorMsg,
      });
      return null;
    }
    setSaveInProgress(true);
    return isAddMode ? createBatch(data) : updateBatch(id, data);
  };

  const handleCancelClick = () => {
    window.close();
    // isAddMode ? history.push('/batches') : history.push('/batches');
  };

  const updateSpecialTrekData = (specialTrek: ISpecialTrekTypeModel | null = null) => {
    setSelectedSpecialTrekValue(specialTrek || null);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onReset={() => reset}>
        <Toast ref={toast} />
        <div className="d-flex align-items-center details-title">
          <div className="flex-grow-1">
            <div className="display-1">{isAddMode ? 'Add Group' : 'Edit Group'}</div>
          </div>
          <div>
            <Link to="/batches">
              <i className="pi pi-times icon-dark-color" style={{ fontSize: '1.2rem' }} />
            </Link>
          </div>
        </div>
        {!dataLoaded && <Overlay text="Loading..." icon="pi-spinner" iconSpin />}
        <div className="p-grid">
          <div className="p-col-12 p-lg-8 p-md-12">
            <div className="card p-fluid">
              <h4>Group details</h4>
              <div className="p-grid">
                <div className="p-col-12 p-md-12">
                  <div>
                    <div className="p-mb-3">
                      <div className="p-mb-2 required">
                        <label htmlFor="trekId">Trek:</label>
                      </div>
                      <div>
                        <Controller
                          name="trekId"
                          control={control}
                          render={({ onChange, value }) => (
                            <AutoComplete
                              placeholder="Search"
                              autoFocus
                              dropdown
                              forceSelection
                              value={selectedTrekValue}
                              onChange={(e) => {
                                setSelectedTrekValue(e.value);
                                bindSeasons(e.value?.trekId);
                                onChange(e.value?.trekId);
                              }}
                              suggestions={autoFilteredValue}
                              disabled={!isAddMode}
                              completeMethod={autoSearchTrek}
                              field="name"
                            />
                          )}
                        />
                        {errors.trekId && (
                          <span className="p-error">
                            <p>Error:{errors.trekId?.message}</p>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="p-mb-3">
                      <div className="p-mb-3">
                        <Checkbox
                          checked={isSpecialBatch}
                          onChange={() => {
                            setIsSpecialBatch((prevState) => !prevState);
                            updateSpecialTrekData();
                          }}
                          disabled={!isAddMode}
                        />
                        <span className="mx-2">
                          <b>Create Special Batch</b>
                        </span>
                      </div>
                      {isSpecialBatch && (
                        <div className="p-mb-3">
                          <div className="p-mb-2">
                            <label htmlFor="specialTrek">Special Trek Type:</label>
                          </div>
                          <div>
                            <Controller
                              name="specialTrek"
                              control={control}
                              render={({ onChange, value }) => (
                                <AutoComplete
                                  placeholder="Search"
                                  dropdown
                                  value={selectedSpecialTrekValue}
                                  onChange={(e) => {
                                    onChange(e.value?.id);
                                    updateSpecialTrekData(e.value);
                                  }}
                                  suggestions={specialTrekFilteredValues}
                                  completeMethod={specialTreksProcessedValues}
                                  disabled={!isAddMode}
                                  field="name"
                                />
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="p-grid">
                      <div className="p-col-12 p-md-12">
                        <div className="required">
                          <div className="p-mb-2">
                            <label htmlFor="seasonId">Seasons:</label>
                          </div>
                          <div>
                            <Controller
                              name="seasonId"
                              control={control}
                              render={({ onChange, value }) => (
                                <AutoComplete
                                  placeholder={`${selectedTrekValue && !isSeasonsDataLoaded ? 'Loading...' : 'Search'}`}
                                  dropdown
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e.value);
                                    setSelectedSeasonData(e.value);
                                  }}
                                  suggestions={autoSeasonsFilteredValue}
                                  disabled={!isAddMode || !autoSeasonsValueData.length}
                                  completeMethod={autoSearchSeasons}
                                  field="formattedText"
                                />
                              )}
                            />
                            {selectedTrekValue && isSeasonsDataLoaded && !autoSeasonsValueData.length && (
                              <span className="p-error">
                                <p>No Season Found for the Selected Trek</p>
                              </span>
                            )}
                            {errors.seasonId && (
                              <span className="p-error">
                                <p>Error:Season required</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-lg-6 p-md-12">
                        <div className="required">
                          <div className="p-mb-2">
                            <label htmlFor="name">Start Date:</label>
                          </div>
                          <div>
                            <Controller
                              name="startDate"
                              control={control}
                              render={({ onChange, value }) => (
                                <Calendar
                                  dateFormat="dd/mm/yy"
                                  value={value}
                                  onChange={(e) => onChange(e.value)}
                                  monthNavigator
                                  yearNavigator
                                  yearRange="2010:2030"
                                />
                              )}
                            />
                            {errors.startDate && (
                              <span className="p-error">
                                <p>Error:{errors.startDate?.message}</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-lg-6 p-md-12">
                        <div className="required">
                          <div className="p-mb-2">
                            <label htmlFor="endDate">End Date:</label>
                          </div>
                          <div>
                            <Controller
                              name="endDate"
                              control={control}
                              render={({ onChange, value }) => (
                                <Calendar
                                  dateFormat="dd/mm/yy"
                                  value={value}
                                  onChange={(e) => onChange(e.value)}
                                  monthNavigator
                                  yearNavigator
                                  yearRange="2010:2030"
                                />
                              )}
                            />
                            {errors.endDate && (
                              <span className="p-error">
                                <p>Error:{errors.endDate?.message}</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2 required">
                            <label htmlFor="capacity">Capacity:</label>
                          </div>
                          <div>
                            <Controller
                              name="capacity"
                              control={control}
                              defaultValue="0"
                              render={({ onChange, value }) => (
                                <InputNumber value={value} onValueChange={(e: any) => onChange(e.value)} />
                              )}
                            />
                            {errors.capacity && (
                              <span className="p-error">
                                <p>Error:{errors.capacity?.message}</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {!isAddMode && (
                        <>
                          <div className="p-col-12 p-lg-6 p-md-12">
                            <div className="p-mb-2">
                              <label htmlFor="name">Active Bookings:</label>
                            </div>
                            <div>
                              <Controller
                                name="numberOfActiveBooking"
                                control={control}
                                render={({ value }) => <InputText value={value} disabled />}
                              />
                            </div>
                          </div>
                          <div className="p-col-12 p-lg-6 p-md-12">
                            <div className="p-mb-2">
                              <label htmlFor="name">Confirmed Bookings:</label>
                            </div>
                            <div>
                              <Controller
                                name="numberOfConfirmedBooking"
                                control={control}
                                render={({ value }) => <InputText value={value} disabled />}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2 required">
                            <label htmlFor="waitCapacity">Wait Capacity:</label>
                          </div>
                          <div>
                            <Controller
                              name="waitCapacity"
                              control={control}
                              defaultValue="0"
                              render={({ onChange, value }) => (
                                <InputNumber value={value} onValueChange={(e: any) => onChange(e.value)} />
                              )}
                            />
                            {errors.waitCapacity && (
                              <span className="p-error">
                                <p>Error:{errors.waitCapacity?.message}</p>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {!isAddMode && (
                        <>
                          <div className="p-col-12 p-lg-6 p-md-12">
                            <div className="p-mb-2">
                              <label htmlFor="name">Waiting List Bookings:</label>
                            </div>
                            <div>
                              <Controller
                                name="numberOfWaitingListBooking"
                                control={control}
                                render={({ value }) => <InputText value={value} disabled />}
                              />
                            </div>
                          </div>
                          <div className="p-col-12 p-lg-6 p-md-12">
                            <div className="p-mb-2">
                              <label htmlFor="name">Unconfirmed Bookings:</label>
                            </div>
                            <div>
                              <Controller
                                name="numberOfUnConfirmedBooking"
                                control={control}
                                render={({ value }) => <InputText value={value} disabled />}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2">
                            <label htmlFor="name">Trek leader (s):</label>
                          </div>
                          <div>
                            <Controller
                              name="trekLeaders"
                              control={control}
                              render={({ onChange, value }) => (
                                <AutoComplete
                                  placeholder="Search"
                                  dropdown
                                  multiple
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e.value);
                                  }}
                                  suggestions={autoFilteredLeaderUserValue}
                                  completeMethod={autoSearchTrekLeaders}
                                  field="firstName"
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2">
                            <label htmlFor="promoter">Promoter:</label>
                          </div>
                          <div>
                            <Controller
                              name="promoter"
                              control={control}
                              render={({ onChange, value }) => (
                                <AutoComplete
                                  placeholder="Search"
                                  dropdown
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e.value);
                                  }}
                                  suggestions={autoFilteredPromoterUserValue}
                                  completeMethod={autoSearchPromoters}
                                  field="name"
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2">
                            <label htmlFor="description">Description:</label>
                          </div>
                          <div>
                            <Controller
                              name="description"
                              control={control}
                              defaultValue=""
                              render={({ onChange, value }) => <InputText value={value} onChange={onChange} />}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2">
                            <label htmlFor="whatsappGroupLink">WhatsApp group link:</label>
                          </div>
                          <div>
                            <Controller
                              name="whatsappGroupLink"
                              control={control}
                              defaultValue=""
                              render={({ onChange, value }) => <InputText value={value} onChange={onChange} />}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-12">
                        <div>
                          <div className="p-mb-2">
                            <label htmlFor="preTrekBriefingLink">Pre-trek briefing link:</label>
                          </div>
                          <div>
                            <Controller
                              name="preTrekBriefingLink"
                              control={control}
                              defaultValue=""
                              render={({ onChange, value }) => <InputText value={value} onChange={onChange} />}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex p-mt-2">
                <div className="flex-grow-1" />
                <div className="p-mx-2">
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-warning p-button-lg"
                    onClick={handleCancelClick}
                  />
                </div>
                <div className="">
                  <Button
                    type="submit"
                    label="Submit"
                    icon="pi pi-check"
                    className="p-button-lg p-button-secondary"
                    disabled={saveInProgress}
                  >
                    {saveInProgress && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default withLoader(withRouter(BatchDetail));
